import React from "react";
import { useTranslation } from 'react-i18next';

import { ContentBody, Subtitle } from '../commonStyles';

export default function About() {
  const { t } = useTranslation();

  return <ContentBody>
    <Subtitle>{t('about.subtitle')}</Subtitle>
  </ContentBody>;
}
