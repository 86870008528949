import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import { subtitleStyle } from '../commonStyles';

const NavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding-top: 25px;
  border-bottom: 3px solid black;
  width: 100%;
  height: 50px;
`;

const NavLink = styled(Link)`
  ${subtitleStyle};
  text-align: center;
  width: 140px;
  height: 35px;
  margin: 0 15px;
  padding: 5px 15px 0 15px;
  transition: all ease 0.5s;
  border: none;
  font-size: 20px;
  text-decoration: none;
  &:hover {
    height: 40px;
    padding-bottom: 10px;
  }
`;

export default function NavBar() {
  return <NavDiv>
    <NavLink to="/">Home</NavLink>
    <NavLink to="/projects">Projects</NavLink>
    <NavLink to="/about">About</NavLink>
    <NavLink to="/contact">Contact</NavLink>
  </NavDiv>;
}
