import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { subtitleStyle } from '../commonStyles';

const Collapse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  transition: height 0.5s;
  overflow: hidden;
  height: ${(props: { visible: boolean }) => props.visible ? '210px': '0'};
`;

const CollapseLink = styled(Link)`
  ${subtitleStyle};
  text-decoration: none;
  padding: 15px;
  width: 100%;
  text-align: center;
  &:hover {
    font-weight: 900;
  }
`;

export default function CollapseMenu(props: { visible: boolean }) {
  return <Collapse visible={props.visible}>
    <CollapseLink to="/">Home</CollapseLink>
    <CollapseLink to="/projects">Projects</CollapseLink>
    <CollapseLink to="/about">About</CollapseLink>
    <CollapseLink to="/contact">Contact</CollapseLink>
  </Collapse>
}
