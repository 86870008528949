import React from "react";
import { useTranslation } from 'react-i18next';

import { ContentBody, Subtitle, Content, Link } from '../commonStyles';

export default function Contact() {
  const { t } = useTranslation();
  return <ContentBody>
    <Subtitle>{t('contact.subtitle')}</Subtitle>
    <Content>
      {t('contact.content.intro')}
      {t('contact.content.email')}
      <Link href="mailto:vilma.salmikuukka@gmail.com">vilma.salmikuukka@gmail.com</Link>
      {t('contact.content.linkedin')}
      <Link href="https://www.linkedin.com/in/vilma-salmikuukka/">https://www.linkedin.com/in/vilma-salmikuukka/</Link>
    </Content>
  </ContentBody>;
}
