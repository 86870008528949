import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import image5628 from '../img/background_bw-5628.jpg';
import image3840 from '../img/background_bw-3840.jpg';
import image2160 from '../img/background_bw-2160.jpg';
import image1080 from '../img/background_bw-1080.jpg';
import image720 from '../img/background_bw-720.jpg';
import { titleStyle } from '../commonStyles';
import { useWindowSize } from '../utils';

import NavBar from './NavBar';
import CollapseMenu from './CollapseMenu';

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  @media screen and (max-width: 720px) {
    background-image: url(${image720});
  }
  @media screen and (min-width: 721px) and (max-width: 1080px) {
    background-image: url(${image1080});
  }
  @media screen and (min-width: 1081px) and (max-width: 2160px) {
    background-image: url(${image2160});
  }
  @media screen and (min-width: 2161px) and (max-width: 3840px) {
    background-image: url(${image3840});
  }
  @media screen and (min-width: 3841px) {
    background-image: url(${image5628});
  }
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Title = styled.div`
  ${titleStyle};
  width: fit-content;
  margin: 25px auto;
  padding: 0 30px 10px 30px;
  font-size: 70px;
  font-weight: bold;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  font-size: 25px;
  cursor: pointer;
  justify-self: flex-end;
  align-self: flex-end;
`;

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const size = useWindowSize();

  const toggleCollapse = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (isOpen && size.width > 840) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen, size]);

  useEffect(() => {
    setIsOpen(false);
  }, [setIsOpen, location]);

  return <React.Fragment>
    <HeaderDiv>
      <Title>Immis</Title>
      {size.width > 840
        ? <NavBar/>
        : <Button onClick={toggleCollapse}><FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} /></Button>
      }
    </HeaderDiv>
    <CollapseMenu visible={isOpen} />
  </React.Fragment>;
}
