import React from "react";
import { Trans, useTranslation } from 'react-i18next';

import { Subtitle, ContentBody, Content, ContentTitle, Link } from '../commonStyles';

export default function Projects() {
  const { t } = useTranslation();

  return <ContentBody>
    <Subtitle>{t('projects.subtitle')}</Subtitle>
    <Content>
      <ContentTitle>{t('projects.website.title')}</ContentTitle>
      <Trans
        tOptions={{ joinArrays: ' ' }}
        i18nKey="projects.website.content"
        components={{
          ReactLink: <Link href="http://www.reactjs.org" />,
          TSLink: <Link href="https://www.typescriptlang.org/" />,
          SCLink: <Link href="https://styled-components.com/" />,
          i18nLink: <Link href="https://www.i18next.com/" />,
          GitLink: <Link href="https://gitlab.com/helmikerttu/immis-website" />
        }}
      />
    </Content>
  </ContentBody>;
}
