import React from 'react';
import { useTranslation } from 'react-i18next';

import { Note, Bold } from '../commonStyles';

export default function WIPNote() {
  const { t } = useTranslation();

  return <Note>
    <Bold>{t('note.title')}</Bold>
    {t('note.wip')}
  </Note>;
}
