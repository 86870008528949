import React from 'react';
import { useTranslation } from 'react-i18next';

import { Subtitle, ContentBody, Content, ContentBox, Comment } from '../commonStyles';

export default function Home() {
  const { t } = useTranslation();

  return <ContentBody>
    <Subtitle>{t('home.subtitle')}</Subtitle>
    <Content>{t('home.content.intro', { joinArrays: ' ' })}</Content>
    <Content>{t('home.content.technologies', { joinArrays: ' ' })}</Content>
    <ContentBox>
      <Comment>// Frontend</Comment>
        - JavaScript / TypeScript  <br />
        - React / Redux <br />
        - Angular <br /><br />
      <Comment>// Backend</Comment>
        - Python / Flask <br />
        - Kotlin / Spring <br />
        - Node.js <br /><br />
      <Comment>// Desktop & Mobile</Comment>
        - C++ / Qt / QML <br />
        - Flutter <br />
    </ContentBox>
  </ContentBody>;
}
