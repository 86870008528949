import styled, { css } from 'styled-components';

export const titleStyle = css`
  font-family: "Segoe UI";
  color: white;
  background-color: black;
`;

export const subtitleStyle = css`
  ${titleStyle};
  font-weight: 700;
`;

export const Subtitle = styled.div`
  ${subtitleStyle};
  width: fit-content;
  margin: 20px 0;
  padding: 2px 5px;
  font-size: 25px;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 840px;
  margin: 25px auto;
  padding: 2px 45px;
`;

export const Content = styled.div`
  font-family: "Segoe UI";
  color: black;
  margin: 10px 0;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Consolas";
  border-radius: 3px;
  background-color: #dedede;
  color: #535353;
  margin: 30px 0;
  padding: 20px 30px;
`;

export const Link = styled.a`
  white-space: nowrap;
  font-family: "Consolas";
  font-weight: 500;
  background-color: #f1c7c7;
  color: #e54141;
  border-radius: 3px;
  padding: 1px 2px;
  margin: 0 3px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Comment = styled.span`
  color: green;
`;

export const Note = styled.div`
  width: fit-content;
  margin-bottom: 30px;
  font-family: "Segoe UI";
  color: #915fca;
  background-color: #ede6f6;
`;

export const Bold = styled.span`
  font-weight: 800;
`;

export const ContentTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
`;
